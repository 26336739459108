.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 6.4rem;

  background: var(--background-gradient);
  border: none;
  caret-color: transparent;
  cursor: pointer;
  transition: background-color ease-in 500ms;
  z-index: 1;
  height: 5.2rem;
}

.btn:hover {
  background-color: var(--hover);
}

.btn:active {
  background: none;
  background-color: var(--click);
}

.btn_text {
  height: 2rem;

  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
}

@media (max-width: 480px) {
  .btn {
    width: 100%;
    margin-top: 0.8rem;
  }
}
