.bg {
  background: linear-gradient(
      0deg,
      rgba(8, 15, 31, 0.95),
      rgba(8, 15, 31, 0.95)
    ),
    url("../../images/footer/background-panele-fotowoltaiczne-zachod-slonca-dom-fotowoltaika-dach-odygo-bialystok.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  max-width: 144rem;
  padding: 6.4rem 8.8rem;
  margin: 0 auto;
  color: white;
  flex-direction: column;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4.8rem;
}

.map {
  width: 62.3rem;
  height: 47rem;
  border: 0;
}

.footer {
  height: 22rem;
  position: relative;
  width: 100%;
}

.flex_column {
  flex-direction: column;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copywrite {
  position: absolute;
  bottom: 0;
}

.social_media {
  position: absolute;
  bottom: 0;
  right: 0;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.6rem;
  cursor: pointer;
}

.gap_9 {
  gap: 9.6rem;
}

.gap_12 {
  gap: 12.4rem;
}

.gap_8 {
  gap: 0.8rem;
}

.gap_16 {
  gap: 1.6rem;
}

.gap_24 {
  gap: 2.4rem;
}

.gap_55 {
  gap: 5.5rem;
}

.gap_229 {
  gap: 22.9rem;
}

.logo {
  width: 19.7rem;
  margin-bottom: 2.6rem;
}

.small_text {
  color: #838383;
  font-size: 12px;
}

@media (max-width: 480px) {
  .container {
    max-width: 32.7rem;
    padding: 2.4rem 0;
  }

  .footer {
    height: auto;
  }

  .flex {
    flex-direction: column;
    gap: 2.4rem;
  }

  .map {
    width: 32.7rem;
    height: 18.4rem;
  }

  .copywrite {
    position: static;
  }

  .text {
    max-width: 24.5rem;
  }

  .social_media {
    position: static;
    flex-direction: row;
    justify-content: center;
    margin: 2.4rem 0;
  }

  .small_text {
    text-align: center;
  }

  .gap_55 {
    gap: 0.8rem;
  }
}
