:root {
  --placeholder-color: #838383;
  --focus-color: #faf0e6;
}

.input {
  padding: 1.4rem 12.4rem 1.4rem 1.6rem;
  gap: 1rem;

  width: 51.7rem;
  height: 4.9rem;

  border: 2px solid #ffffff;
  font-size: 1.6rem;
  color: var(--placeholder-color);
  background-color: transparent;
  z-index: 2;
  font-family: inherit;
}

.input:focus,
.input:hover {
  color: var(--focus-color);
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  border-width: 3px;
}

.input:focus {
  transition: 500ms ease;
}

.rich {
  height: 16.3rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  z-index: 1;
}

::-webkit-input-placeholder {
  color: var(--placeholder-color);
}

::-moz-placeholder {
  color: var(--placeholder-color);
}

::-ms-placeholder {
  color: var(--placeholder-color);
}

::placeholder {
  color: var(--placeholder-color);
}

:focus::-webkit-input-placeholder {
  color: var(--focus-color);
}

:hover::-moz-placeholder {
  color: var(--focus-color);
}

:hover::-ms-placeholder {
  color: var(--focus-color);
}

:hover::placeholder {
  color: var(--focus-color);
}

/* Focus placeholder */
:focus::-webkit-input-placeholder {
  color: var(--focus-color);
}

:focus::-moz-placeholder {
  color: var(--focus-color);
}

:focus::-ms-placeholder {
  color: var(--focus-color);
}

:focus::placeholder {
  color: var(--focus-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: white;
}

@media (max-width: 480px) {
  .input {
    width: 32.7rem;
    height: 4.9rem;
  }
}
