.gallery {
  display: grid;
  grid: auto / repeat(6, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
}

.gallery .galleryItem:nth-child(5n),
.gallery .galleryItem:nth-child(5n-2),
.gallery .galleryItem:nth-child(5n-1) {
  grid-column: span 2;
}

.galleryItem {
  grid-column: span 3;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  /* z-index: 1; */
}

.galleryItem {
  position: relative;
  color: white;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}

.galleryItem::after {
  animation: 300ms fadeOut forwards;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../images/gallery/left_click.svg),
    linear-gradient(
      90deg,
      rgba(235, 30, 50, 0.5) 0%,
      rgba(246, 166, 50, 0.5) 100%
    );
  background-repeat: no-repeat;
  background-position: center;
  transition: all 500ms;
}

.img:hover,
.galleryItem:hover .img {
  transform: scale(1.1);
  transition: 500ms;
}

.galleryItem:active::after {
  animation: 300ms;
}

.galleryItem:hover::after {
  animation: 300ms fadeIn forwards;
}

.galleryItem:active::after {
  background-image: url(../../images/gallery/left_click.svg),
    linear-gradient(0deg, rgba(246, 166, 50, 0.8), rgba(246, 166, 50, 0.8));
  background-repeat: no-repeat;
  background-position: center;
}

.img {
  transition: transform 300ms ease-in;
  z-index: -1;
}

.galleryItem:hover .text {
  animation: 500ms fadeOut forwards;
}

.text {
  position: absolute;
  bottom: 1.6rem;
  left: 1.6rem;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.8rem;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  animation: 300ms fadeIn forwards;
  z-index: 2;
}

@keyframes fadeIn {
  0% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
