.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12.3rem;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.image {
  width: 62.4rem;
}

@media (max-width: 480px) {
  .content {
    flex-direction: column;
    gap: 1.6rem;
  }

  .image {
    width: 100%;
  }
}
