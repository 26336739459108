.control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.8rem;
  cursor: pointer;
}

.box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  caret-color: transparent;
  border: 1px solid white;
  transition: background 300ms ease;
}

.box:hover {
  background: rgba(255, 255, 255, 0.2);
}

.input {
  display: none;
}

.small_text {
  max-width: 38.5rem;
  margin-left: 2.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 120%;
  caret-color: transparent;
}
