.text {
  max-width: 83.7rem;
}

.images_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.6rem;
  max-width: 126.4rem;
  color: white;
}

.image_container {
  width: 41.1rem;
  height: 66.4rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: width 1s ease;
}

.image_container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.image_container:hover {
  width: 62.4rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.headline {
  position: absolute;
  animation: 1s fadeIn forwards;
  bottom: 3.2rem;
  left: 2.4rem;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.image_container:hover .detail {
  animation: 1s fadeIn forwards;
}

.image_container:hover .headline {
  animation: 1s fadeOut forwards;
}

.detail {
  animation: 1s fadeOut forwards;
  z-index: 1;
  opacity: 0;
  position: absolute;
  bottom: 3.2rem;
  left: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  width: 51.2rem;
}

@media (max-width: 480px) {
  .images_container {
    flex-direction: column;
  }

  .image_container {
    width: 32.7rem;
    height: 20rem;
  }

  .image_container:hover {
    width: 32.7rem;
  }

  .image_container:hover .detail {
    animation: none;
    opacity: 0;
  }

  .detail {
    opacity: 0;
    display: none;
  }

  .image_container:hover .headline,
  .headline {
    animation: none;
  }

  .image {
    object-position: 50% 32%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
