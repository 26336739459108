.arrow {
  cursor: pointer;
  position: absolute;
  bottom: 50%;
}

.nextArrow {
  right: -6.4rem;
}

.prevArrow {
  left: -6.4rem;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  caret-color: transparent;
  gap: 0.8rem;
}

.navigation-absolute {
  position: absolute;
  bottom: 2.4rem;
  left: 40%;
}

.slick-track {
  display: flex !important;
}

.slick-dots {
  margin-top: 1.6rem !important;
  position: static !important;
}

.slick-dots li {
  margin: 0 !important;
  width: auto !important;
  height: 0.8rem !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
}

li.slick-active {
  background: linear-gradient(116.59deg, #e73964 8.47%, #f6a632 82.02%);
}

.navigation-absolute li.slick-active {
  background: none;
  background-color: white;
}

@media (max-width: 480px) {
  .arrow {
    display: none;
  }
}
