.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.8rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.4rem;
}

.content-container p {
  max-width: 83.7rem;
}

.baner {
  height: 70rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-grey:nth-child(2n + 1) {
  background-color: var(--grey);
}

.bg-grey {
  background-color: var(--grey);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
}

.grid-gap {
  column-gap: 1.6rem;
  row-gap: 4.8rem;
}

.grid-big {
  row-gap: 6.4rem;
  columns: 4.8rem;
}

.mobile-container {
  display: none;
}

.center-xl {
  align-items: center;
}

.m-gap {
  gap: 3.2rem;
}

@media (max-width: 480px) {
  .container,
  .m-gap,
  .grid-gap {
    gap: 1.6rem;
    row-gap: 1.6rem;
  }

  .mobile-container {
    display: block;
  }

  .center-xl {
    align-items: normal;
  }

  .grid-xl {
    display: none;
  }

  .display-xl {
    display: none;
  }
}
