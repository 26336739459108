.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 10.4rem 0;
  gap: 2.8rem;
  flex-grow: 2;
  max-width: 25.2rem;
  height: 100%;
}

.number {
  font-size: 6.4rem;
  font-weight: 700;
}

.text {
  font-weight: 400;
  font-size: 2rem;
  line-height: 130%;
  text-align: center;
  display: flex;
  padding: 0 1rem;
  height: 5.2rem;
}

.bg_0 {
  background: rgba(231, 57, 100, 0.9);
}

.bg_1 {
  background: rgba(235, 85, 87, 0.9);
}

.bg_2 {
  background: rgba(239, 113, 74, 0.9);
}

.bg_3 {
  background: rgba(242, 137, 63, 0.9);
}

.bg_4 {
  background: rgba(246, 166, 50, 0.9);
}

@media (max-width: 480px) {
  .container {
    max-width: 100%;
    padding: 9.6rem 0 4.8rem;
    gap: 2.4rem;

    width: 100vw;
    height: 44rem;
  }
}
