.icon {
  cursor: pointer;
}

.icon stop {
  transition: stop-color 300ms ease;
}

.icon:hover stop:last-child {
  stop-color: var(--hover);
}

.icon:active stop:first-child,
.icon:active stop:last-child {
  stop-color: var(--click);
}

.phone {
  opacity: 0.7;
}

.up {
  margin-left: 2.4rem;
}

@media (max-width: 480px) {
  .up {
    display: none;
  }
}
