.main_padding {
  padding: 0 0.8rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem 4rem;
  gap: 1.6rem;

  width: 41.1rem;
  min-height: 44.1rem;

  border: 0.4rem solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    116.59deg,
    #e73964 8.47%,
    #f6a632 82.02%
  );
  transition: border-image-source 400ms;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.8rem;
}

.avatar {
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
}

.text {
  max-width: 330rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 130%;
  text-align: center;
  color: black;
}

.container:hover {
  border-image-source: linear-gradient(
    116.59deg,
    #f6a632 8.47%,
    #e73964 82.02%
  );
  border-width: 4px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 480px) {
  .container {
    width: 32.7rem;
    min-height: 53rem;
  }
}
