.thumbnail {
  position: relative;
  color: white;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  height: 36rem;
  width: 41.1rem;
  object-position: 30%;
}

.thumbnail::after {
  animation: 300ms fadeOut forwards;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(235, 30, 50, 0.5) 0%,
    rgba(246, 166, 50, 0.5) 100%
  );
}

.only_title:active::after {
  background: linear-gradient(
    0deg,
    rgba(246, 166, 50, 0.8),
    rgba(246, 166, 50, 0.8)
  );
}

.img:hover,
.thumbnail:hover .img {
  filter: brightness(1.3);
  transform: scale(1.1);
  transition: 500ms;
}

.only_title:hover .img {
  filter: none;
}

.thumbnail:active::after {
  animation: 300ms fadeIn forwards;
}

.img {
  object-fit: cover;
  object-position: 50% 50%;
  transition: transform 300ms ease-in;
  z-index: -1;
}

.thumbnail:hover .text {
  animation: 500ms fadeOut forwards;
}

.text {
  position: absolute;
  bottom: 1.6rem;
  left: 1.6rem;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.8rem;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  animation: 1s fadeIn forwards;
}

.big_text {
  color: white;
  position: absolute;
  bottom: 6.4rem;
  left: 4.4rem;
  font-size: 6.4rem;
  font-weight: 700;
  animation: 1s fadeIn forwards;
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 480px) {
  .thumbnail {
    width: 32.7rem;
    height: 36rem;
  }

  .big_text {
    font-size: 5rem;
  }

  .img:hover {
    transform: none;
    filter: none;
  }

  .thumbnail:hover .text {
    animation: none;
  }
}
