.baner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--black);
}

.bg {
  height: 64rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;
  padding-top: 22rem;
  padding-left: 19.5rem;
  color: var(--white);
}
