* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
::before,
::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.special {
  color: var(--click);
}

.bold {
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

.menu-padding {
  padding: 0.8rem 8.8rem !important;
}

.cursor {
  cursor: pointer;
}

@media (max-width: 480px) {
  .menu-padding {
    display: none;
    padding: 0 0 0.8rem 0 !important;
  }
}
