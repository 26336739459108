.baner {
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.baner_content {
  display: inline-flex;
  gap: 3.5rem;
}

.description {
  display: flex;
  max-width: 94.4rem;
  flex-direction: column;
  justify-content: center;
}

.main_container {
  max-width: 144rem;
  padding: 6.4rem 8.8rem;
  margin: 0 auto;
  display: flex;
  gap: 6.4rem;
  flex-direction: column;
  caret-color: transparent;
}
