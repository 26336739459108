:root {
  --max-width-xl: 144rem;
}

.header-container {
  max-width: var(--max-width-xl);
  padding: 6.4rem 8.8rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  background: transparent;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.2rem;
}

.header-bg {
  background: #f2f2f2;
}

.max-text {
  max-width: 83.7rem;
}

.list {
  font-size: 1.6rem;
}

@media (max-width: 480px) {
  .flex {
    gap: 1.6rem;
  }

  .disable-mobile {
    display: none;
  }

  .header-container {
    max-width: 100%;
    padding: 2.4rem 0;
    gap: 1.6rem;
    justify-content: flex-start;
  }

  .side-padding {
    padding-left: 2.4rem;
  }

  .mobile {
    padding: 0;
  }
}
