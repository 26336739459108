.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 11.9rem;
  row-gap: 4.8rem;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.4rem;
  padding: 3.2rem 0;
}

.image {
  width: 62.5rem;
  height: 34.3rem;
}

.split_image {
  width: 30.4rem;
  height: 42.2rem;
  object-fit: cover;
}

.images_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.6rem;
}

@media (max-width: 480px) {
  .content {
    flex-direction: column;
    row-gap: 1.6rem;
    column-gap: 1.6rem;
  }

  .reverse_column {
    flex-direction: column-reverse;
  }

  .image {
    width: 32.7rem;
    height: 42.4rem;
    object-fit: cover;
    object-position: 39%;
  }

  .split_image:last-child {
    display: none;
  }
}
