.mobile_slider {
  display: none;
}

@media (max-width: 480px) {
  .mobile_slider {
    display: block;
  }

  .main_padding {
    padding: 0 0.8rem;
  }
}
