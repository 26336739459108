.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.4rem;
}

@media (max-width: 480px) {
  .card {
    padding: 0.8rem 0;
  }
}
