nav {
  position: sticky;
  z-index: 2;
  background: var(--background-dark);
}

.nav_container {
  max-width: 144rem;
  margin: 0 auto;
}

.menu_bar {
  padding: 3.2rem 8.8rem 0 8.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  cursor: pointer;
  caret-color: transparent;
  width: 19.7rem;
  z-index: 1;
}

.small_menu {
  display: none;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4rem;
  z-index: 1;
}

.menu_item {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-transform: uppercase;
  transition: color ease 300ms;
  caret-color: transparent;
}

.menu_item:hover {
  color: var(--hover);
}

.menu_item:active {
  color: var(--click);
}

.icon_links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.6rem;
}

.phone_container {
  position: fixed;
  bottom: 4.8rem;
  right: 4.8rem;
}

.sticky {
  top: 0;
  opacity: 0.9;
  background: #080f1f;
}

@media (max-width: 480px) {
  .nav_container {
    max-width: 32.7rem;
    padding-top: 2.4rem;
  }

  .menu_bar {
    padding: 0;
    gap: 7.4rem;
  }

  .menu {
    display: none;
  }

  .small_menu {
    display: block;
  }

  .logo {
    width: 15.6rem;
  }

  .phone_container {
    display: none;
  }
}
