.popUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  gap: 2.4rem;
  text-align: center;
  justify-content: center;
  padding: 4.8rem 4.6rem;
  width: 62.3rem;
  border-radius: 2.4rem;
  background-color: white;
  opacity: 0.8;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px) drop-shadow(8px 8px 20px rgba(0, 0, 0, 0.7));
  transition: 500ms ease;
}

.container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83.8rem;
  height: 72rem;
  border-radius: 1.6rem;
  animation: mymove 500ms forwards;
  animation-delay: 2s;
  top: -100rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@keyframes mymove {
  from {
    display: none;
  }
  to {
    top: 15vh;
    display: flex;
  }
}

.closeBtn {
  cursor: pointer;
  position: absolute;
  top: 2.7rem;
  right: 2.2rem;
}

.text {
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.textBold {
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
}

@media (max-width: 480px) {
  .overlay {
    display: none;
  }
}
