.bg_left {
  background: rgba(231, 57, 100, 0.9);
  flex-grow: 1;
  height: 100%;
}

.bg_right {
  background: rgba(246, 166, 50, 0.9);
  flex-grow: 1;
  height: 100%;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  height: 56.7rem;
  background: url("../../images/company-numbers/background-panele-fotowoltaiczne-firma-liczby-odygo-bialystok.webp");
  background-repeat: no-repeat;
  background-size: cover;
  align-self: stretch;
  background-position: 90%;
  margin: 0 auto;
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  top: 6.4rem;
  z-index: 1;
}

.mobile {
  display: none;
}

@media (max-width: 480px) {
  .container {
    display: none;
  }

  .flex {
    height: 44rem;
    justify-content: center;
  }

  .mobile {
    display: block;
    width: 100%;
    height: 100%;
  }

  .title {
    top: 2.4rem;
  }
}
