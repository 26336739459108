.main_container {
  max-width: 144rem;
  padding: 0 8.8rem 17.5rem 8.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  caret-color: transparent;
}

.bg {
  height: 72rem;
}

.container {
  width: 126.4rem;
  height: 64rem;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
  text-shadow: 0 2px 5px #00081d;
}

.title {
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.2rem;
  z-index: 1;
  max-width: 55.6rem;
}

.subtitle {
  margin-top: -1.6rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.6rem;
  max-width: 62.4rem;
  z-index: 1;
}

.image {
  visibility: hidden;
  position: absolute;
  left: 31%;
  top: 9%;
  z-index: 0;
  width: 108.2rem;
  animation: slide 500ms forwards;
  animation-delay: 1s;
  caret-color: transparent;
}

@keyframes slide {
  0% {
    left: 100%;
  }
  40% {
    left: 20%;
  }
  100% {
    left: 31%;
    visibility: visible;
  }
}

@media (max-width: 480px) {
  .main_container {
    padding: 0 2.4rem 2.4rem 2.4rem;
  }

  .container {
    width: auto;
    height: 53.1rem;
  }

  .header_container {
    align-items: center;
    gap: 0.8rem;
    max-width: 32.7rem;
  }

  .title {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }

  .subtitle {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
  }

  .image {
    width: 40.5rem;
    position: absolute;
    z-index: 1;
    top: -3%;
    left: -10%;
    visibility: visible;
    animation: none;
    animation-delay: 0;
  }
}
