.header_container {
  max-width: 144rem;
  padding: 6.4rem 8.8rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  background: transparent;
}

.header_bg {
  background: #f2f2f2;
}

@media only screen and (max-width: 480px) {
  .header_container {
    max-width: 32.7rem;
    padding: 2.4rem 0;
    gap: 1.6rem;
    justify-content: flex-start;
  }
}
