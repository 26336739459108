:root {
  --background-dark: rgba(8, 15, 31, 0.95);
  --background-gradient: linear-gradient(130deg, #e73964 8.47%, transparent)
    #f6a632;
  --hover: #e73964;
  --click: #f6a632;
  --white: #ffffff;
  --black: #000000;
  --grey: #f2f2f2;
}

.black {
  color: var(--black);
}
