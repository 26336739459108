.menu_item {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-transform: uppercase;
  transition: color ease 300ms;
  caret-color: transparent;
}

.menu_item:hover,
.container:hover .main_item {
  color: var(--hover);
}

.menu_item:active,
.container:active .main_item {
  color: var(--click);
}

.container {
  z-index: 1;
  display: flex;
  position: relative;
}

.arrow {
  height: 2.4rem;
  cursor: pointer;
}

.arrow path {
  transition: fill 300ms ease;
}

.container:hover .arrow path,
.arrow:hover path {
  fill: var(--hover);
}

.container:active .arrow path,
.arrow:active path {
  fill: var(--click);
}

.container:hover .list {
  visibility: visible;
}

.list {
  visibility: hidden;
  width: 22rem;
  position: absolute;
  top: 2.3rem;
  color: var(--black);
  background-color: var(--white);
  padding: 0.3rem 0.7rem;
  z-index: 1;
  transition: visibility 500ms;
}

.list a {
  display: block;
}

.list :hover {
  background: rgba(231, 57, 100, 0.1);
}

.list .menu_item {
  transition: background 300ms ease;
}

@keyframes hideElement {
  0% {
    display: none;
  }
  100% {
    display: block;
  }
}
