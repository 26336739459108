.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #080f1f;
  caret-color: transparent;
  margin-bottom: 4.8rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 1px;
  gap: 0.8rem;

  font-weight: 700;
  position: relative;
  top: 4rem;

  opacity: 0.8;
}

.title {
  font-size: 16rem;
  line-height: 120%;
}

.subtitle {
  font-size: 4rem;
  line-height: 4rem;
}

.list {
  margin-top: 1.6rem;
  margin-bottom: 4rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 130%;
  text-align: center;
  list-style-position: inside;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.8rem;

  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4rem;
  cursor: pointer;
}

.image {
  height: 70rem;
}
