.cert {
  height: 43.8rem;
}

.horizontalCerts {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.horizontalCert {
  width: 57.7rem;
}
