body {
  font-family: "Montserrat", sans-serif;
  color: white;
}

.header-text {
  font-size: 2rem;
  font-weight: 500;
  line-height: 130%;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.description-text {
  font-size: 2rem;
  font-weight: 500;
  line-height: 130%;
  max-width: 62.4rem;
}

.bodycopy-xl {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 130%;
}

.large-text {
  font-size: 6.4rem;
  font-weight: 700;
  line-height: 120%;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.1rem;
}

h1 {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 5.2rem;
}

h2 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
}

h3 {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 4rem;
}

h4 {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 120%;
}

h5 {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 120%;
}

@media (max-width: 480px) {
  h2 {
    font-size: 3rem;
    line-height: 3rem;
    max-width: 32.7rem;
  }

  h3 {
    font-size: 2.8rem;
  }
}
