.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.6rem;
  row-gap: 4.8rem;
}

.mobile_slider {
  display: none;
}

@media (max-width: 480px) {
  .content {
    display: none;
    flex-direction: column;
    gap: 1.6rem;
  }

  .mobile_slider {
    display: block;
  }
}
